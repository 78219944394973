import * as React from 'react'
import { Grid, styled, Theme, Typography } from '@mui/material'
import CircleType from 'circletype'

import { ItemLink } from 'Styles'
import { EventVendor } from 'Types'

const RoundedImg = styled('img')(() => ({
  borderRadius: '50%',
  '&:hover': {
    boxShadow: '5px 5px #888888'
  }
}))

// Needed access to ref on the Grid
const StyledGrid = styled(Grid)(() => ({}))

const styles = {
  title: {
    textAlign: 'left'
  },
  vendorHandle: {
    position: 'relative',
    top: '-1.5em'
  },
  vendorImages: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      marginRight: 9.25
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: 5
    },
    [theme.breakpoints.up(2240)]: {
      margin: 8
    }
  })
}

const Vendors: React.FC<EventVendor> = ({ vendors }) => {
  const vendorElement = React.useRef<HTMLDivElement>(null)
  const [hasUpdated, setHasUpdated] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (vendorElement && !hasUpdated) {
      setTimeout(() => {
        // For some reason if there isnt a little delay the characters arent spaced properly
        for (let i = 0; i < vendors.length; i++) {
          new CircleType(document.getElementById(`vendor-${i}`)).radius(120).dir(-1)
        }
      }, 200)
      setHasUpdated(true)
    }
  }, [vendorElement, hasUpdated, setHasUpdated, vendors.length])

  return (
    <Grid item xs={8} sx={styles.title}>
      <Typography variant='h4' sx={{ mb: 2 }}>
        Vendors
      </Typography>
      <StyledGrid
        container
        // spacing={2}
        alignItems={'baseline'}
        justifyContent='space-evenly'
        ref={vendorElement}
      >
        {vendors.map((vendor, i) => {
          // if (vendor.url && (!vendor.url.includes('https://') || !vendor.url.includes('Https://')))
          //   vendor.url = 'https://' + vendor.url
          return (
            <Grid key={vendor.handle} item xs={10} md={4} lg={2} xl={2} sx={styles.vendorImages}>
              <ItemLink to={{ pathname: vendor.url }} target='_blank'>
                <RoundedImg src={vendor.image} alt={vendor.alt} width='100%' />
              </ItemLink>
              <Typography variant='h5' id={`vendor-${i}`} sx={styles.vendorHandle}>
                {vendor.handle}
              </Typography>
            </Grid>
          )
        })}
      </StyledGrid>
    </Grid>
  )
}

export default Vendors
