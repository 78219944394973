import { styled } from '@mui/material'
import * as React from 'react'

import { ItemLink } from 'Styles'

type CarouselItemProps = {
  image: string
  url: string
  alt: string
}

const CarouselItem: React.FC<CarouselItemProps> = ({ image, url, alt }) => {
  const ItemContainer = styled('div')(({ theme }) => ({
    height: '20vh'
  }))

  return (
    <ItemLink to={url}>
      <ItemContainer>
        <img src={image} height='inherit' width='inherit' alt={alt} />
      </ItemContainer>
    </ItemLink>
  )
}

export default CarouselItem
